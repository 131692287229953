import React from "react";
import SocialItem from "../components/SocialItem";
import socialData from "../data/SocialData";
import "../css/Socials.css";

const Social = () => (
    <div className="social-container-wrapper container">
        <div className="social-header text-gradient">
            <span>Social Media Links</span>
        </div>
        <div className="social-container">
            {socialData.map((data, key) => (
                <SocialItem data={data} key={key} />
            ))}
        </div>
    </div>
);

export default Social;
