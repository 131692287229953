import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";

function NavigationBar() {
    const styleObj = {
        fontSize: 32,
        paddingTop: "15px",
    };

    return (
        <Navbar
            sticky="top"
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
        >
            <Container>
                <Navbar.Brand href="/home">
                    <img
                        src="/logo_w.svg"
                        width="40"
                        height="40"
                        // className="d-inline-block align-top"
                        alt="logo"
                    />
                </Navbar.Brand>
                <Navbar.Brand href="/home">
                    <p style={styleObj}>Devin</p>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/about">About Me</Nav.Link>
                        <Nav.Link href="/resume">My Journey</Nav.Link>
                        <NavDropdown
                            title="Projects"
                            id="collasible-nav-dropdown"
                        >
                            <NavDropdown.Item href="/projects/overview">
                                Overview
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/projects/endofworktimer">
                                EndOfWorkTimer
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/projects/findmyid">
                                FindMyID
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/projects/daswombat">
                                Discord Bot "Das Wombat"
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                href="https://github.com/Devinoir"
                                target={"_blank"}
                            >
                                GitHub
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Nav.Link href="/socials">Socials</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;
