import React, { Component } from "react";

export default class APICancel extends Component {
    render() {
        return (
            <div className="default-container-wrapper container">
                <div className="default-header text-gradient">
                    <span>Your transaction was cancelled.</span>
                </div>
                <span className="default-text">
                            If you have any problem, please contact me via one of my socials or send a mail to <span style={{color: "azure", fontWeight: "bold"}}>devin.neugebauer@outlook.de</span>
                </span>
            </div>
        );
    }
}
