import React, { Component } from "react";
import "../css/About.css";

export default class About extends Component {
    render() {
        return (
            <div className="header-content-wrapper container">
                <div className="header-content row">
                    <div className="about-content-text col-12 col-xl-7">
                        <p>
                            My name is Devin. I'm 23 years old, living near Berlin.
                            Currently, I am working at SER Group as an IT-Consultant.
                        </p>

                        <p>
                            I mostly code in .NET. For Halloween 2022 <a href="https://hedt.dev" target={"_blank"} rel="noreferrer">a friend</a> an I created a WebApp from scatch where I was in charge of the backend.
                            You can find an example of my work with ASP.NET on my <a href="https://github.com/Devinoir/VotingAPI_public" target={"_blank"} rel="noreferrer">GitHub page</a>.
                        </p>

                        <p>
                            This website was also made by me using ReactJS.
                        </p>
                    </div>
                    <div className="about-content-image col-12 col-xl-5">
                        <img src="me2.png" height={512} alt="me"/>
                    </div>
                </div>
            </div>
        );
    }
}
