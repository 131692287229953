const timelineData = [
    {
        text: 'Higher education entrance qualification (A-levels) specializing in design and media technology',
        date: '2014 - June 14 2019',
        category: {
			tag: 'education',
			color: '#3371FF'
		},
    },
    {
        text: 'Certificate of Chamber of Industry and Commerce in application development',
        date: 'August 2019 - July 2021',
        category: {
			tag: 'education',
			color: '#3371FF'
		},
    },
    {
        text: 'IT Consultant at SER Group - Berlin',
        date: 'July 1 2021 - now',
        category: {
			tag: 'experience',
			color: '#0FB677'
		},
        link: {
            url: 'https://www.sergroup.com/',
            text: 'See SER'
        }
    }
]

export default timelineData;
