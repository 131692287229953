import React from 'react'

function ErrorPage() {
    const styleObj = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
    };
    
    return (
        <img src='/404.png' alt='404 Page not found.' style={styleObj}></img>
    );
}

export default ErrorPage
