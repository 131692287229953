import timelineData from "../data/TimelineData";
import TimelineItem from "./TimelineItem";
import "../css/Timeline.scss";

const Timeline = () =>
    timelineData.length > 0 && (
        <div>
            <div className="vertical-dotted-line"></div>
            <div className="timeline-container">
                {timelineData.map((data, key) => (
                    <TimelineItem data={data} key={key}/>
                ))}
            </div>
        </div>
    );

export default Timeline;
