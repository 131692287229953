import './css/index.css';
import "bootstrap/dist/css/bootstrap.css";
import "./css/Stars.scss"
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import ErrorPage from "./Pages/ErrorPage";
import Socials from "./Pages/Socials";
import Resume from "./Pages/Resume";
import ProjectCarousel from "./Pages/Overview";
import APICancel from "./Pages/APICancel";
import APISuccess from "./Pages/APISuccess";


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    {/* Routing */}
    <Routes>
        <Route path="" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="resume" element={<Resume />} />
        <Route path="socials" element={<Socials />} />
        <Route path="projects/overview" element={<ProjectCarousel />} />
        <Route path="cancel" element={<APICancel />} />
        <Route path="success/*" element={<APISuccess />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
