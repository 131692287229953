import NavigationBar from "./components/NavigationBar";
import React, { Component } from "react";

export class App extends Component {
    render() {
        return (
            <>
                <NavigationBar />
                {/* Star Background generating */}
                <div id="stars-container">
                    <div id="stars"></div>
                    <div id="stars2"></div>
                    <div id="stars3"></div>
                </div>
            </>
        );
    }
}
export default App;
