import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialItem = ({ data }) => (
    <div className="social-item">
        <a href={data.link} target={"_blank"} rel="noreferrer">
            <div className="social-content">
                <div className="social-content-icon">
                    <FontAwesomeIcon icon={data.icon} size="3x" />
                </div>
                <span>{data.text}</span>
            </div>
        </a>
    </div>
);

export default SocialItem;
