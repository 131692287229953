import React, { Component } from "react";
import "../css/App.css";
import "../css/Home.css";

export default class Home extends Component {
    render() {
        return (
            <div className="header-content-wrapper container">
                <div className="header-content row">
                    <div className="header-content-text col-12 col-xl-7">
                        <span className="header-content-greeting fadeInDelay05">
                            Hey, my name is
                        </span>
                        <p className="header-content-name fadeInDelay1 shine">
                            <span>Devin</span>
                            <span>Neugebauer</span>
                        </p>
                        <span className="header-content-description fadeInDelay2">
                            and this my personal website where you can get to
                            know me and my projects.
                        </span>
                    </div>
                    <div className="header-content-image fadeInDelaySlow1 col-12 col-xl-5">
                        <img src="logo512.png" width={512} alt="" />
                    </div>
                    <span className="header-content-mail fadeInDelay2">
                            devin.neugebauer@outlook.de
                    </span>
                </div>
            </div>
        );
    }
}
