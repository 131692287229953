import { faTwitter, faGithub, faInstagram, faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons'


const socialData = [
    {
        icon: faGithub,
        text: 'GitHub',
        link: 'https://github.com/Devinoir',
    },
    {
        icon: faInstagram,
        text: 'Instagram',
        link: 'https://www.instagram.com/_devinoir/',
    },
    {
        icon: faTwitter,
        text: 'Twitter',
        link: 'https://twitter.com/_devinoir',
    },
    {
        icon: faYoutube,
        text: 'YouTube',
        link: 'https://www.youtube.com/channel/UCLB8twVXWZZQu1Z4HbUOegQ',
    },
    {
        icon: faTwitch,
        text: 'Twitch',
        link: 'https://www.twitch.tv/wombatse',
    }
]

export default socialData;
