import React, { Component } from 'react'
import ProjectCarousel from '../components/ProjectCarousel'

export class Overview extends Component {
    render() {
        return (
            <div className='default-wrapper'>
                <ProjectCarousel/>
            </div>
        )
    }
}

export default Overview