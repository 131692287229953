import React from "react";
import Timeline from "../components/Timeline";

export default function Resume() {
    return (
        <div>
            <div className="timeline-wrapper container">
                <div className="timeline-header text-gradient">
                    <span>My Journey</span>
                </div>
            </div>
            <Timeline />
        </div>
    );
}
