import React, { Component } from "react";
import { useLocation } from "react-router-dom";

export default class APISuccess extends Component {
    render() {
        return (
            <div className="default-container-wrapper container">
                <div className="default-header text-gradient">
                    <span>Your transaction was successfull.</span>
                </div>
                <span className="default-text">
                            If you have any problem, please contact me via one of my socials or send a mail to <span style={{color: "azure", fontWeight: "bold"}}>devin.neugebauer@outlook.de</span>
                <SessionID/>
                </span>
            </div>
        );
    }
}

function SessionID(){
    let { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const sessionID = queryParams.get('session_id');

    console.log(sessionID);

    return(
        <div>
            <span>SessionID: {sessionID}</span>
        </div>
    );
}
